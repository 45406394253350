<template>
<div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column  flex-row-fluid"
      id="kt_login"
    >
      <!--begin::Content-->
      <KTHeader></KTHeader>
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-20 mb-20">
            <v-app>
              <!--begin::Content header-->
              <!--end::Content header-->

              <!--begin::Signin-->
              <div v-if="isStudentinfo" class="login-form login-signin bg-white p-2">
                <div class="icon-close"><router-link
                    class="font-weight-bold font-size-3 ml-2"
                    :to="{ name: 'landing' }"
                  ><v-icon class="mdi-close" md >mdi-close</v-icon></router-link></div>
                <div class="text-center mb-5 mb-lg-10">
                  <h3 class="login-header-title">Iniciar sesión</h3>
                  <p class="login-header-playing text-muted font-weight-semi-bold">
                  ¿Estás listo para aprender jugando?
                  </p>
                </div>

                <!--begin::Form-->
                <div class="signin-logo mb-4">
                  <v-img :src="require('@/assets/images/avlogo.svg')"></v-img>
                </div>
                <v-form ref="form" lazy-validation
                  @submit.prevent="onSubmit">
                  
                  <b-form-group
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                    class="pr-7 pl-7"
                  >

                    <div
                      role="alert"
                      v-bind:class="{ 'd-none': !errors.length, 'show': errors.length}"
                      class="alert alert-danger mt-4"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                    <v-text-field
                       single-line
                        outlined 
                      label="Nombre de usuario"
                      prepend-inner-icon="mdi-account"
                      v-model="form.email"
                      :rules="form.emailRules"
                    ></v-text-field>


                    <v-text-field
                      outlined
                      single-line
                      label="Contransena"
                      :type="show1 ? 'text' : 'password'"
                      prepend-inner-icon="mdi-lock"
                      v-model="form.password"
                      :rules="form.passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                    ></v-text-field>

                  </b-form-group>
                
                  <!--begin::Action-->
                  <div
                    class="login-action d-flex flex-wrap justify-content-center align-items-center pr-7 pl-7"
                  >
                    <a
                      href="#"
                      class="text-dark-60 text-hover-primary my-3 mr-12"
                      id="kt_login_forgot"
                      @click="forgotPassLink()"
                    >
                    ¿Olvidaste tu contraseña?
                    </a>
                    <button
                      ref="kt_login_signin_submit"
                      class="login-button btn btn-success font-weight-bold py-4 px-8 my-3 font-size-3 rounded-pill"
                    >
                    Iniciar sesión en FastMind
                    </button>
                  </div>
                  <!--end::Action-->
                </v-form>
                <div
                class="top-0 right-0 text-right mb-15 mb-lg-0 flex-column-auto justify-content-center pr-9 pb-5 pt-3"
                >
                  <router-link
                    class="font-weight-bold mr-2 register-des"
                    :to="{ name: 'register' }"
                  >
                    ¿No tienes una cuenta? Regístrate ahora
                  </router-link>
                </div>
                <!--end::Form-->
              </div>
              <div v-if="isForgotPassword" class="forgot-form login-signin bg-white p-2">
                <div class="icon-close" @click="StudentinfoLink()"><v-icon class="mdi-close" md >mdi-close</v-icon></div>
                <div class="text-center mb-4 mb-lg-10 px-12">
                  <h3 class="login-forgot-title">He olvidado la contraseña o el nombre de usuario.</h3>
                  <p class="login-header-playing text-muted font-weight-semi-bold">
                  Introduce el correo electrónico para restablecer tu contraseña.
                  </p>
                </div>
                <v-form ref="form" lazy-validation
                  @submit.stop.prevent="onSubmit">
                  
                  <div
                    class="px-24 pb-4"
                  >
                    <div
                      role="alert"
                      v-bind:class="{ 'd-none': !errors.length, 'show': errors.length}"
                      class="alert fade alert-danger mt-4"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>

                    <v-text-field
                       single-line
                        outlined 
                      label="Nombre de usuario"
                      prepend-inner-icon="mdi-account"
                      v-model="form.email"
                      :rules="form.emailRules"
                    ></v-text-field>

                  </div>
                
                  <!--begin::Action-->
                  <div
                    class="login-action d-flex flex-wrap justify-content-center align-items-center px-24"
                  >

                    <button
                      ref="kt_login_signin_submit"
                      class="forgot-button btn btn-success font-weight-bold py-4 mb-5 font-size-3 rounded-pill"
                    >
                    Send email to reset password
                    </button>
                  </div>
                  <!--end::Action-->
                </v-form>

                <!--end::Form-->
              </div>
              
              <!--end::Signin-->
            </v-app>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre&family=Courier+Prime&display=swap');
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import KTHeader from "@/view/layout/header/Header.vue";
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "login",
  components: {
    KTHeader,
  },
  data() {
    return {
      // Remove this dummy login info
      show1: false,
      form: {
        email: "",
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: "",
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length > 5) || "Password must be bigger than 8 characters",
        ],
        valid: true,
      },
   
      isStudentinfo:true,
      isForgotPassword:false
    };
  },
  methods: {
    forgotPassLink() {
      this.isStudentinfo = false;
      this.isForgotPassword = true;
    },
    StudentinfoLink() {
      this.isStudentinfo = true;
      this.isForgotPassword = false;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };
    },
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const email = this.form.email;
      const password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            this.$router.push({ name: "begin" });
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });

        
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
